<template>
    <div class="q-pa-md top-level row q-gutter-md justify-evenly items-stretch">

        <q-card class="my-card" v-for="(doc,idx) in docs" :key="'doc_'+idx">
            <q-card-section class="card-section-picture">
                <q-img :src="doc.picture" />
            </q-card-section>
            <q-card-section class="card-section-description">
                <div class="text-h6" v-html="doc.title"></div>
                <div class="text-subtitle2" v-html="doc.description" />
            </q-card-section>
            <q-card-actions>
                <a :href="doc.url" target="_blank" class="openUrl">OPEN</a>
            </q-card-actions>
        </q-card>

    </div>
</template>

<script>
export default ({
    name: 'Docwriter',
    data() {
        return {
            docs: [
                {
                    picture: '/static/documents/eyer_install_guide.png',
                    title: '<em>Eyer</em> installation guide',
                    description: 'Document explaining the installation procedure for the <em>Eyer</em> plugin.',
                    url: '/static/documents/eyer_v1.2_install_guide.pdf',
                },
                {
                    picture: '/static/documents/how_to_write_an_xml_report_in_symphony.png',
                    title: '<em>How-to</em> for perl scripting',
                    description: 'This short document explains how to create a report in Sirsidynics Shymphony using XML. It was intended to help people at the European Parliament\'s library.',
                    url: '/static/documents/perl how to write an xml report in symphony.pdf',
                },
                {
                    picture: '/static/documents/regular_expressions_can_be_legible.png',
                    title: '<em>How-to</em> document regular expressions',
                    description: 'This short document explains how to document --and troubleshoot-- regular expressions, in perl.',
                    url: '/static/documents/regular expressions can be legible - eprs staff - eprs wiki.pdf',
                },
                {
                    picture: '/static/documents/scripting_in_surveyor.png',
                    title: 'Scripting in TQ-Surveyor',
                    description: 'This 20+ pages document explains how to exploit the scripting function (Groovy language) made available in TQ-Surveyor, but not documented.',
                    url: '/static/documents/scripting_in_surveyor_1.pdf',
                },
                {
                    picture: '/static/documents/catalogue_de_poids.jpg',
                    title: 'Inventory catalog',
                    description: 'Creation of an inventory catalog (1000+ weights and scales) with pictures and descriptions.',
                    url: '/static/documents/catalogue_q75.pdf',
                },

            ],
        };
    },
})
</script>

<style scoped lang="less">
.top-level {}
.my-card {
    max-width: 300px;
    color: #666;
    display: flex;
    flex-direction: column;
    .card-section-picture {
        flex:0 0 200px;
        text-align: center;
        overflow:hidden;
    }
    .card-section-description {
        flex: 1 1;
        background-color:#f0f0f0;
        border-top: 1px solid DarkGray;
        border-bottom: 1px solid DarkGray;
        .text-h6 {
            font-weight: bold;
        }
    }
    .q-card__actions {
        flex:0 0;
        .openUrl {
            text-decoration: none;
            color: #777;
            font-weight: bolder;
        }
    }
}
</style>
